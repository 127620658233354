html {
  background: url('../../assets/images/background-cave-new-up.jpg');
}

body {
  background-color: transparent !important;
  color: black !important;
  // @import url("https://fonts.googleapis.com/css?family=Poppins:400,700,900");
  font-family: 'Quantico', Helvetica, Arial, Lucida, sans-serif !important;
}

::-webkit-scrollbar {
  width: 6px !important;
}

::-webkit-scrollbar-track {
  background: #121212 !important;
}

::-webkit-scrollbar-thumb {
  background: #444 !important;
  border-radius: 10px !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #333 !important;
}

:root {
  --ff: 'Gilroy', sans-serif;
  --ff2: 'Sentient', serif;
  --fs: calc(13px + 2 * (100vw - 320px) / 1080);
  --fs_title: calc(20px + 15 * (100vw - 320px) / 1080);
  --cubic: cubic-bezier(0.305, 0.045, 0.355, 1);
  --transi: 0.4s var(--cubic);
  --ct: calc(650px + 400 * (100vw - 320px) / 1080);
  --color_1_rgb: 240, 221, 202;
  --color_1: rgb(var(--color_1_rgb));
  --color_2_rgb: 186, 56, 253;
  --color_2: rgb(var(--color_2_rgb));
  --color_3_rgb: 206, 59, 255;
  --color_3: rgb(var(--color_3_rgb));
  --color_4_rgb: 124, 38, 255;
  --color_4: rgb(var(--color_4_rgb));
  --color_5_rgb: 58, 38, 29;
  --color_5: rgb(var(--color_5_rgb));
  --color_6_rgb: 26, 20, 21;
  --color_6: rgb(var(--color_6_rgb));
  --color_7_rgb: 199, 185, 170;
  --color_7: rgb(var(--color_7_rgb));
  --kxa_1_rgb: 255, 58, 104;
  --kxa_1: rgb(var(--kxa_1_rgb));
  --kxa_2_rgb: 181, 39, 160;
  --kxa_2: rgb(var(--kxa_2_rgb));
  --kxs_1_rgb: 85, 214, 255;
  --kxs_1: rgb(var(--kxs_1_rgb));
  --kxs_2_rgb: 118, 161, 249;
  --kxs_2: rgb(var(--kxs_2_rgb));
  --bg_1_rgb: 0, 0, 0;
  /* 99, 56, 31 */
  --bg_1: rgb(var(--bg_1_rgb));
  --bx_1: 0 0 0 1px rgba(255, 255, 255, 0.05), 0 0 0 1px #000 inset;
  --bx_2: 0 0 0 1px rgba(255, 255, 255, 0.05) inset, 0 0 0 1px #000;
  --blur: blur(2px);
  --opac: 0.3;
  --opac-dropdown: 0.9;
  --tx: 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px 6px rgba(0, 0, 0, 0.2);
  --radius_1: 5px;
  --green: rgb(58, 255, 90);
}

.modal-backdrop {
  background: rgba(0, 0, 0, 0.6) !important;
}

.main-app {
  user-select: none;
  // font-family: "Quantico", serif;
  // font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;

  .f-h1 {
    font-size: calc(23px + 8 * (100vw - 320px) / 1080);
  }

  .f-sub {
    font-size: calc(16px + 1 * (100vw - 320px) / 1080);
  }

  .f-init {
    font-size: initial;
  }

  .f-h2 {
    font-size: calc(18px + 5 * (100vw - 320px) / 1080);
  }

  .f-h3 {
    font-size: calc(24px + 10 * (100vw - 320px) / 1080);
  }

  .action-btns {
    margin: auto;
    max-width: 70%;
  }

  .action-btns a {
    // transition: 0.5s all;
    display: inline-block;
    padding: 12px 20px;
    margin: 0px 10px;
    margin-top: 10px;
    width: 210px;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    background-size: cover;
    font-size: calc(16px + 1 * (100vw - 320px) / 1080);
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .action-btns a:hover {
    background-position: 0 0;
  }

  .btn-play-now {
    background: url('../../assets/images/button_sprite.png') 0 -59px no-repeat;
  }

  .btn-trailer {
    background: url('../../assets/images/button_sprite.png') 0 -59px no-repeat;
  }

  .btn-buy {
    background: url('../../assets/images/button_sprite.png') 0 -59px no-repeat;
  }

  .section1 {
    height: 100vh;
    background: url('../../assets/images/cryptolegions_concept_art.jpg') center
      center no-repeat;
    background-size: cover;
    position: relative;
    margin-bottom: 4%;

    .section1-content-title {
      text-shadow: 2px 2px #000;
    }

    .section1-content {
      text-align: center;
      font-weight: bold;
      position: absolute;
      top: 25%;
    }

    .section1-content-lg {
      right: 10%;
      // width: 50%;
    }

    .top-cloud {
      position: absolute;
      top: 0;
      left: 0;
      // right: 0;
      width: 40%;
    }

    .main-bottom-cave img {
      width: 100%;
      position: absolute;
      bottom: 0px;
      transform: translateY(17%);
    }

    .particles-bg-canvas-self {
      z-index: 0 !important;
    }
  }

  .section2 {
    // height: 100vh;
    // background: url("../../assets//images/section1-background.jpg") center
    //   center no-repeat;
    background-size: cover;
    position: relative;
    margin-bottom: 4%;
    margin-top: 2%;

    video {
      width: 100% !important;
      z-index: 1;
    }

    .main-top-cave img {
      position: absolute;
      top: 0px;
      transform: translateY(-22%);
      width: 100%;
      z-index: 10;
    }

    .main-bottom-cave img {
      width: 100%;
      position: absolute;
      bottom: 0px;
      transform: translateY(20%);
      z-index: 10;
    }

    .trailer-play-btn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: rgba(var(--bg_1_rgb), var(--opac));
      backdrop-filter: var(--blur);
      padding: 20px 30px;
      border: white;
      border-radius: 10px;
      color: white;
      font-weight: bold;
      cursor: pointer;
      transition: 0.4s all;
    }

    .trailer-play-btn:hover {
      opacity: 1;
      transition: 0.4s all;
      background: red;
    }
  }

  .section3 {
    background: url('../../assets//images/roadmap-bg-new.jpg') center center
      no-repeat;
    background-size: cover;
    position: relative;
    // margin-bottom: 4%;
    // margin-top: 4%;
    padding-bottom: 15%;

    .main-top-cave img {
      position: absolute;
      top: 0px;
      transform: translateY(-11%);
      width: 100%;
    }

    .main-bottom-cave img {
      width: 100%;
      position: absolute;
      bottom: 0px;
      // transform: translateY(20%);
    }

    .actionButton a {
      z-index: 10;
    }

    .phase-bg {
      border: 5px solid #242424 !important;
      background-repeat: no-repeat !important;
      background-position-x: 95% !important;
      background-position-y: 95% !important;
      // background-size: cover !important;
      background-size: 110% 120% !important;
      border-radius: 5px;
      margin-left: 5px !important;
      background-color: white !important;
    }
  }

  .section4 {
    position: relative;
    // margin-bottom: 4%;
    // margin-top: 2%;
    padding-top: 5%;
    padding-bottom: 12%;

    .main-top-cave img {
      position: absolute;
      top: 0px;
      transform: translateY(-11%);
      width: 100%;
    }

    .main-bottom-cave img {
      width: 100%;
      position: absolute;
      bottom: 0px;
      // transform: translateY(20%);
    }

    .team-member {
      position: relative;
      color: white;
    }

    .team-member1 {
      position: relative;
      color: white;
    }

    .team-member1 .member-avatar {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -60%);
      width: 30%;
      border-radius: 50%;
    }

    .team-member1 .member-title {
      position: absolute;
      left: 50%;
      // bottom: 0;
      top: 63%;
      transform: translateX(-50%);
    }

    .actionButton a {
      z-index: 10;
    }
  }

  .section5 {
    position: relative;
    // margin-bottom: 4%;
    padding-top: 6%;
    margin-top: -3%;
    // padding-bottom: 2%;
    background: url('../../assets/images/top-section-img.png');
    background-size: 100% auto;
    background-repeat: no-repeat;
    // background-position-y: 30%;
    // padding-top: 8%;

    .company-title:hover {
      color: #2988e3;
      cursor: pointer;
      transition: 0.4s all;
    }
  }

  .section5 .social-item {
    transition: 0.4s all;
  }

  .section5 .social-item:hover {
    transition: 0.4s all;
    transform: translateY(-30%);
  }

  .sectionROI {
    background: black;
    position: relative;
    // margin-bottom: 4%;
    // margin-top: 2%;
    padding-top: 5%;
    padding-bottom: 8%;

    .main-top-cave img {
      position: absolute;
      top: 0px;
      transform: translateY(-11%);
      width: 100%;
    }

    .main-bottom-cave img {
      width: 100%;
      position: absolute;
      bottom: 0px;
      transform: translateY(25%);
    }

    label {
      z-index: 100;
    }

    span {
      z-index: 100;
    }
  }

  @media (min-width: 0px) and (max-width: 639px) {
    .section1-content {
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
      top: 13% !important;
    }

    .team-member {
      background: url('../../assets/images/team-cave.png');
      background-size: 200% 100%;
      padding-top: 45%;
      padding-bottom: 48%;
      padding-left: 10%;
      padding-right: 10%;
      background-position: center;
    }

    .team-member .member-avatar {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, -0%);
      width: 25%;
      border-radius: 50%;
    }

    .section5-content {
      padding-top: 1%;
    }
  }

  @media (min-width: 640px) and (max-width: 767px) {
    .action-btns a {
      height: calc(60px / 1.3);
      width: calc(210px / 1.3);
      background-position: 0 -45px;
      padding: 0;
      margin: 0;
    }

    .action-btns {
      width: 80%;
    }

    .action-btns a:hover,
    .action-btns a:active {
      background-position: 0 0px;
    }

    .action-btns {
      flex-wrap: nowrap;
      max-width: initial;
    }
    .section1-content {
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
      top: 13% !important;
    }

    .team-member {
      background: url('../../assets/images/team-cave.png');
      background-size: 180% 100%;
      padding-top: 20%;
      padding-bottom: 25%;
      padding-left: 10%;
      padding-right: 10%;
      background-position: center;
    }

    .team-member .member-avatar {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, -25%);
      width: 25%;
      border-radius: 50%;
    }

    .section5-content {
      padding-top: 5%;
    }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    .section1-content {
      width: 80%;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
    }

    .team-member {
      background: url('../../assets/images/team-cave.png');
      background-size: 180% 100%;
      padding-top: 20%;
      padding-bottom: 25%;
      padding-left: 10%;
      padding-right: 10%;
      background-position: center;
    }

    .team-member .member-avatar {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, -10%);
      width: 20%;
      border-radius: 50%;
    }

    .section5-content {
      padding-top: 5%;
    }
  }

  @media (min-width: 1024px) {
    .section1-content {
      right: 10% !important;
      left: 50%;
      transform: translateX(-50%);
      width: 80%;
    }

    .team-member {
      background: url('../../assets/images/team-cave.png');
      background-size: 100% 100%;
      padding-top: 30%;
      padding-bottom: 35%;
      padding-left: 30%;
      padding-right: 30%;
      background-position: center;
    }

    .team-member .member-avatar {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, -25%);
      width: 25%;
      border-radius: 50%;
    }

    .section5-content {
      padding-top: 5%;
    }
  }
}

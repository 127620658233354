#nav-bar {
  --t: 0.6s var(--cubic);
  --h: 55px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9000;
  width: 100%;
  color: var(--color_1);
}

.text-glow {
  text-shadow: 1px 1px 10px rgb(253, 54, 54), 1px 1px 10px rgb(255, 146, 43);
}
.nav-bar-container {
  width: 100%;
  top: 10px;
  background-color: rgba(var(--bg_1_rgb), var(--opac));
  position: fixed;
  display: flex;
  backdrop-filter: var(--blur);
}
.nav-bar {
  display: flex;
  padding: 0 calc(30px + 15 * (100vw - 320px) / 1080);
  transition: top var(--t);
  height: var(--h);
  align-items: center;
  /* top: calc(0px + 20 * (100vw - 320px) / 1080); */
  /* box-shadow: var(--bx_1); */
}
.header-sticky .nav-bar {
  top: 10px;
}
.nav-bar .logo {
  z-index: 1000;
  transition: var(--t);
  display: flex;
  width: 500px;
  transform: translateY(-32%) translateX(-10%);
  top: 0;
}
.nav-bar .logo img {
  width: 100%;
  height: auto;
}
.header-sticky .nav-bar .logo {
  width: 400px;
  transform: translateY(-115%);
}
.nav-bar #nav-list {
  height: 100%;
  font-size: calc(15px + 1 * (100vw - 320px) / 1080);
  font-weight: 700;
  text-shadow: 2px 2px 2px rgb(0 0 0 / 50%);
}
.nav-bar .nav-item {
  padding: 0px 20px;
  cursor: pointer;
  transition: 0.4s all;
  color: #2afeff;
}
.nav-bar .nav-item:hover {
  color: #2988e3;
  transition: 0.4s all;
}

.nav-bar .nav-item-special {
  padding: 0px 20px;
  cursor: pointer;
  transition: 0.4s all;
  text-shadow: 1px 1px 10px rgb(253, 54, 54), 1px 1px 10px rgb(255, 146, 43);
}

.nav-item-green-special {
  /* font-size: calc(13px + 1 * (100vw - 320px) / 1080); */
  padding: 0px 20px;
  cursor: pointer;
  transition: 0.4s all;
  text-shadow: 1px 1px 10px #9affff, 1px 1px 10px #02feff;
}

.nav-bar .nav-item-green-special:hover {
  text-shadow: 1px 1px 10px #2988e3, 1px 1px 10px #2988e3;
}

.dropdown-menu .nav-item {
  padding-top: 10px;
}
.lang {
  --tr: 0.6s;
  --n: 13;
  --w: 23px;
  --p: 5px;
  --mt: 14.5px;
  width: 50px;
  z-index: 1000;
  position: relative;
}
.lang > div {
  position: absolute;
  width: var(--w);
  top: calc(50% - var(--w) / 2);
  left: 20px;
}
.lang .select {
  order: 0;
}
.lang > div:before {
  transition: opacity var(--tr);
  opacity: 0;
  content: '';
  position: absolute;
  left: calc(var(--p) * -1);
  top: calc(var(--p) * -1);
  border-radius: 100px;
  /* background-color: rgba(var(--bg_1_rgb), var(--opac)); */
  box-shadow: var(--bx_2), 0 0 25px rgba(0, 0, 0, 0.8);
  /* backdrop-filter: var(--blur); */
  width: calc(100% + var(--p) * 2);
  height: calc(100% + var(--p) * 2);
}
.lang:hover > div:before {
  opacity: 1;
}
.lang:hover > div ul {
  height: calc((var(--w) + var(--mt)) * var(--n));
}
.lang > div ul li img {
  border-radius: 50%;
}
.lang > div ul li {
  order: 1;
  cursor: pointer;
  z-index: 2000;
  border: 1px solid #333;
  border-radius: 50%;
  padding: 1px;
  margin-bottom: 4px;
}
.lang:hover > div ul li {
  cursor: pointer;
  z-index: 2000;
  border: 1px solid #333;
  border-radius: 50%;
  margin-bottom: 4px;
}
.lang > div ul {
  display: flex;
  flex-direction: column;
  border-radius: 100px;
  transition: var(--tr);
  overflow: hidden;
  height: var(--w);
  list-style: none;
}
.lang > div ul li:hover {
  border: 1px solid rgb(255, 255, 255);
  transition: 0.4s all;
}
@media (min-width: 0px) and (max-width: 639px) {
  .logo {
    width: 80% !important;
    transform: translateY(0%) !important;
  }
  /* .nav-bar {
    height: auto;
  } */
  .header-sticky .logo {
    width: 80% !important;
    transform: translateY(0%) !important;
  }
  .menu-hamburger {
    position: absolute;
    right: 20px;
    border: 1px solid white;
    border-radius: 10px;
    background: #272727;
  }
  .dropdown-menu {
    bottom: -4px;
    position: absolute;
    right: 20px;
    transform: translateY(100%);
    border: 1px solid #333;
    border-radius: 3px;
    padding: 20px;
    background-color: rgba(var(--bg_1_rgb), var(--opac-dropdown));
    /* box-shadow: var(--bx_1); */
    backdrop-filter: var(--blur);
    transition: 0.4s all;
    font-size: calc(13px + 1 * (100vw - 320px) / 1080);
    font-weight: 700;
    text-shadow: 0 1px 2px rgb(0 0 0 / 50%);
  }
}

@media (min-width: 640px) and (max-width: 767px) {
  .menu-hamburger {
    position: absolute;
    right: 20px;
    border: 1px solid white;
    background: #272727;
    border-radius: 10px;
  }
  .dropdown-menu {
    bottom: -4px;
    position: absolute;
    right: 20px;
    transform: translateY(100%);
    border: 1px solid #333;
    border-radius: 3px;
    padding: 20px;
    background-color: rgba(var(--bg_1_rgb), var(--opac-dropdown));
    /* box-shadow: var(--bx_1); */
    backdrop-filter: none;
    transition: 0.4s all;
    font-size: calc(13px + 1 * (100vw - 320px) / 1080);
    font-weight: 700;
    text-shadow: 0 1px 2px rgb(0 0 0 / 50%);
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .menu-hamburger {
    position: absolute;
    right: 20px;
    border: 1px solid white;
    background: #272727;

    border-radius: 10px;
  }
  .dropdown-menu {
    bottom: -4px;
    position: absolute;
    right: 20px;
    transform: translateY(100%);
    border: 1px solid #333;
    border-radius: 3px;
    padding: 20px;
    background-color: rgba(var(--bg_1_rgb), var(--opac-dropdown));
    /* box-shadow: var(--bx_1); */
    backdrop-filter: var(--blur);
    transition: 0.4s all;
    font-size: calc(13px + 1 * (100vw - 320px) / 1080);
    font-weight: 700;
    text-shadow: 0 1px 2px rgb(0 0 0 / 50%);
  }
}
